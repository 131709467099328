import Line from './Line';
import HoverSound from "./HoverSound";
import ClickSound from "./ClickSound";
function Skills() {
  return (
    <>
      <div className="border border-primary-2 mb-2 md:mb-5 lg:mb-0 relative flex flex-col">
        <div className="border-b border-b-primary-2 min-h-10 h-10 flex items-center px-2 md:px-5">
          <p className="xl:text-md lg:text-sm text-sm whitespace-nowrap  text-primary-4 uppercase">skills:</p>
        </div>
        <div className="hidden md:flex lg:hidden">
          <Line />
        </div>

        <div className="w-full flex flex-col items-center md:items-start overflow-y-auto">
          <div className="px-1 lg:5 xl:px-7 pt-9 font-normal ">
            <div className="flex mb-5 sm:mb-7 xl:mb-7">

              <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 text-primary-7 text-md sm:text-lg xl:text-xl icon-agile ">
                  <i className="absolute left-0 top-0 w-full h-full border-l border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <div className="group-hover/skills:opacity-1 opacity-0 transition-all duration-150 absolute top-[-36px] border border-1 z-2 border-primary-6 pointer-events-none bg-primary-2 rounded-sm p-1">
                    <span className="text-xs text-primary-7 font-c0d3 whitespace-nowrap flex flex-col font-bold tracking-wider text-center uppercase leading-3">
                      <span>Culture</span>
                      <span>Agile & Scrum</span>
                    </span>
                  </div>
                </HoverSound>
              </ClickSound>

              <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 text-primary-7 text-md sm:text-lg xl:text-xl icon-figma ">
                  <i className="absolute left-0 top-0 w-full h-full border-l border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <div className="group-hover/skills:opacity-1 opacity-0 transition-all duration-150 absolute top-[-36px] border border-1 z-2 border-primary-6 pointer-events-none bg-primary-2 rounded-sm p-1">
                    <span className="text-xs text-primary-7 font-c0d3 whitespace-nowrap flex flex-col font-bold tracking-wider text-center uppercase leading-3">
                      <span>Figma</span>
                    </span>
                  </div>
                </HoverSound>
              </ClickSound>

              <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 text-primary-7 text-md sm:text-lg xl:text-xl icon-html5 ">
                  <i className="absolute left-0 top-0 w-full h-full border-l border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <div className="group-hover/skills:opacity-1 opacity-0 transition-all duration-150 absolute top-[-36px] border border-1 z-2 border-primary-6 pointer-events-none bg-primary-2 rounded-sm p-1">
                    <span className="text-xs text-primary-7 font-c0d3 whitespace-nowrap flex flex-col font-bold tracking-wider text-center uppercase leading-3">
                      <span>Html5</span>
                    </span>
                  </div>
                </HoverSound>
              </ClickSound>

              <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 text-primary-7 text-md sm:text-lg xl:text-xl icon-css3 ">
                  <i className="absolute left-0 top-0 w-full h-full border-l border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <div className="group-hover/skills:opacity-1 opacity-0 transition-all duration-150 absolute top-[-36px] border border-1 z-2 border-primary-6 pointer-events-none bg-primary-2 rounded-sm p-1">
                    <span className="text-xs text-primary-7 font-c0d3 whitespace-nowrap flex flex-col font-bold tracking-wider text-center uppercase leading-3">
                      <span>Css3</span>
                    </span>
                  </div>
                </HoverSound>
              </ClickSound>

              <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 text-primary-7 text-md sm:text-lg xl:text-xl icon-scss ">
                  <i className="absolute left-0 top-0 w-full h-full border-l border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <div className="group-hover/skills:opacity-1 opacity-0 transition-all duration-150 absolute top-[-36px] border border-1 z-2 border-primary-6 pointer-events-none bg-primary-2 rounded-sm p-1">
                    <span className="text-xs text-primary-7 font-c0d3 whitespace-nowrap flex flex-col font-bold tracking-wider text-center uppercase leading-3">
                      <span>Scss</span>
                    </span>
                  </div>
                </HoverSound>
              </ClickSound>

              <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 text-primary-7 text-md sm:text-lg xl:text-xl icon-tailwindcss ">
                  <i className="absolute left-0 top-0 w-full h-full border-l border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <div className="group-hover/skills:opacity-1 opacity-0 transition-all duration-150 absolute top-[-36px] border border-1 z-2 border-primary-6 pointer-events-none bg-primary-2 rounded-sm p-1">
                    <span className="text-xs text-primary-7 font-c0d3 whitespace-nowrap flex flex-col font-bold tracking-wider text-center uppercase leading-3">
                      <span>Tailwind</span>
                    </span>
                  </div>
                </HoverSound>
              </ClickSound>

            </div>

            <div className="flex mb-10 xl:ml-8 ml-[1.4rem] sm:ml-[1.9rem]">
           
            <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 text-primary-7 text-md sm:text-lg xl:text-xl icon-javascript ">
                  <i className="absolute left-0 top-0 w-full h-full border-l border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <div className="group-hover/skills:opacity-1 opacity-0 transition-all duration-150 absolute bottom-[-36px] border border-1 z-2 border-primary-6 pointer-events-none bg-primary-2 rounded-sm p-1">
                    <span className="text-xs text-primary-7 font-c0d3 whitespace-nowrap flex flex-col font-bold tracking-wider text-center uppercase leading-3">
                      <span>JavaScript</span>
                    </span>
                  </div>
                </HoverSound>
              </ClickSound>

              <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 text-primary-7 text-md sm:text-lg xl:text-xl icon-reactjs ">
                  <i className="absolute left-0 top-0 w-full h-full border-l border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <div className="group-hover/skills:opacity-1 opacity-0 transition-all duration-150 absolute bottom-[-36px] border border-1 z-2 border-primary-6 pointer-events-none bg-primary-2 rounded-sm p-1">
                    <span className="text-xs text-primary-7 font-c0d3 whitespace-nowrap flex flex-col font-bold tracking-wider text-center uppercase leading-3">
                      <span>React</span>
                    </span>
                  </div>
                </HoverSound>
              </ClickSound>


              {/* <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 text-primary-7 text-md sm:text-lg xl:text-xl icon-typescript ">
                  <i className="absolute left-0 top-0 w-full h-full border-l border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <div className="group-hover/skills:opacity-1 opacity-0 transition-all duration-150 absolute bottom-[-36px] border border-1 z-2 border-primary-6 pointer-events-none bg-primary-2 rounded-sm p-1">
                    <span className="text-xs text-primary-7 font-c0d3 whitespace-nowrap flex flex-col font-bold tracking-wider text-center uppercase leading-3">
                      <span>TypeScript</span>
                    </span>
                  </div>
                </HoverSound>
              </ClickSound> */}

              <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 text-primary-7 text-md sm:text-lg xl:text-xl icon-node ">
                  <i className="absolute left-0 top-0 w-full h-full border-l border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <div className="group-hover/skills:opacity-1 opacity-0 transition-all duration-150 absolute bottom-[-36px] border border-1 z-2 border-primary-6 pointer-events-none bg-primary-2 rounded-sm p-1">
                    <span className="text-xs text-primary-7 font-c0d3 whitespace-nowrap flex flex-col font-bold tracking-wider text-center uppercase leading-3">
                      <span>Node</span>
                    </span>
                  </div>
                </HoverSound>
              </ClickSound>

              {/* <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 text-primary-7 text-md sm:text-lg xl:text-xl icon-ethereum ">
                  <i className="absolute left-0 top-0 w-full h-full border-l border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <div className="group-hover/skills:opacity-1 opacity-0 transition-all duration-150 absolute bottom-[-36px] border border-1 z-2 border-primary-6 pointer-events-none bg-primary-2 rounded-sm p-1">
                    <span className="text-xs text-primary-7 font-c0d3 whitespace-nowrap flex flex-col font-bold tracking-wider text-center uppercase leading-3">
                      <span>ethereum</span>
                    </span>
                  </div>
                </HoverSound>
              </ClickSound> */}

              {/* <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 text-primary-7 text-md sm:text-lg xl:text-xl icon-blockchain ">
                  <i className="absolute left-0 top-0 w-full h-full border-l border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <div className="group-hover/skills:opacity-1 opacity-0 transition-all duration-150 absolute bottom-[-36px] border border-1 z-2 border-primary-6 pointer-events-none bg-primary-2 rounded-sm p-1">
                    <span className="text-xs text-primary-7 font-c0d3 whitespace-nowrap flex flex-col font-bold tracking-wider text-center uppercase leading-3">
                      <span>blockchain</span>
                    </span>
                  </div>
                </HoverSound>
              </ClickSound> */}

              <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 text-primary-7 text-md sm:text-lg xl:text-xl icon-bash-shell ">
                  <i className="absolute left-0 top-0 w-full h-full border-l border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <div className="group-hover/skills:opacity-1 opacity-0 transition-all duration-150 absolute bottom-[-36px] border border-1 z-2 border-primary-6 pointer-events-none bg-primary-2 rounded-sm p-1">
                    <span className="text-xs text-primary-7 font-c0d3 whitespace-nowrap flex flex-col font-bold tracking-wider text-center uppercase leading-3">
                      <span>Bash/Shell</span>
                    </span>
                  </div>
                </HoverSound>
              </ClickSound>

              {/* <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 text-primary-7 text-md sm:text-lg xl:text-xl icon-vim ">
                  <i className="absolute left-0 top-0 w-full h-full border-l border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <div className="group-hover/skills:opacity-1 opacity-0 transition-all duration-150 absolute bottom-[-36px] border border-1 z-2 border-primary-6 pointer-events-none bg-primary-2 rounded-sm p-1">
                    <span className="text-xs text-primary-7 font-c0d3 whitespace-nowrap flex flex-col font-bold tracking-wider text-center uppercase leading-3">
                      <span>Vim</span>
                    </span>
                  </div>
                </HoverSound>
              </ClickSound> */}

              <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 text-primary-7 text-md sm:text-lg xl:text-xl icon-git ">
                  <i className="absolute left-0 top-0 w-full h-full border-l border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <div className="group-hover/skills:opacity-1 opacity-0 transition-all duration-150 absolute bottom-[-36px] border border-1 z-2 border-primary-6 pointer-events-none bg-primary-2 rounded-sm p-1">
                    <span className="text-xs text-primary-7 font-c0d3 whitespace-nowrap flex flex-col font-bold tracking-wider text-center uppercase leading-3">
                      <span>Git</span>
                    </span>
                  </div>
                </HoverSound>
              </ClickSound>

              <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 text-primary-7 text-md sm:text-lg xl:text-xl icon-code ">
                  <i className="absolute left-0 top-0 w-full h-full border-l border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <i className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7 after:content-['']  group-hover/skills:after:rotate-[360deg] group-hover/skills:after:opacity-1 after:opacity-0 after:transition-all after:duration-700 after:flex after:absolute after:left-0 after:top-0 after:w-full after:h-full after:bg-primary-2"></i>
                  <div className="group-hover/skills:opacity-1 opacity-0 transition-all duration-150 absolute bottom-[-36px] border border-1 z-2 border-primary-6 pointer-events-none bg-primary-2 rounded-sm p-1">
                    <span className="text-xs text-primary-7 font-c0d3 whitespace-nowrap flex flex-col font-bold tracking-wider text-center uppercase leading-3">
                      <span>Software Engineering</span>
                    </span>
                  </div>
                </HoverSound>
              </ClickSound>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Skills;
