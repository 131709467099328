import SideBar from './SideBar';
import Content from './Content';
import Line from './Line';

function Wrapper() {
    return (
        <>
            <div className="flex flex-col md:flex-row h-full overflow-hidden relative mx-0 px-2 md:px-7 xl:px-9 w-full">
                <div className='relative w-full h-full flex flex-col  md:flex-row'>
                    <div className='hidden md:flex'>
                        <Line />
                    </div>
                    <SideBar />
                    <Content />
                </div>
            </div>
        </>
    );
}
export default Wrapper;
