import Eye from './Eye';

function Header() {
  return (
    <>
      <div className="w-full flex min-h-2 h-2 md:min-h-7 md:h-7 xl:min-h-9 xl:h-9 px-10 justify-center items-center">
      <span className="w-4 h-4 hidden md:flex items-center justify-center icon-illuminati-two text-primary-6 mx-5 text-lg"></span>
        <div className="flex items-center  gap-1">
          <Eye />
          <Eye />
        </div>
        <span className="w-4 h-4 hidden md:flex items-center justify-center icon-illuminati-one text-primary-6 mx-5 text-lg"></span>
      </div>
    </>
  );
}
export default Header;
