import React, { useEffect } from 'react';
const Eye = () => {
  useEffect(() => {
    const handleMouseMove = (event) => {
      const eyes = document.querySelectorAll('#eye #pupil');
      eyes.forEach(eye => {
        const rect = eye.parentElement.getBoundingClientRect();
        const eyeCenterX = rect.left + rect.width / 2;
        const eyeCenterY = rect.top + rect.height / 2;
        const angle = Math.atan2(event.clientY - eyeCenterY, event.clientX - eyeCenterX);
        const distance = 3.9;
        const x = Math.cos(angle) * distance;
        const y = Math.sin(angle) * distance;
        eye.style.transform = `translate(${x}px, ${y}px)`;
      });
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div id='eye' className="w-[17px] h-[17px] bg-white border border-black rounded-full relative hidden md:flex justify-center items-center overflow-hidden">
      <div id='pupil' className="w-[6px] h-[6px] bg-black rounded-full absolute transition  duration-[50ms]"></div>
    </div>
  );
};

export default Eye;
