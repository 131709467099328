function UserInfo() {
  return (
    <>
      <div>
        <div className="flex flex-col mb-2">
          <p className="text-sm md:text-xs text-primary-4  uppercase">name</p>
          <h1 className="text-lg md:text-md  text-primary-7 uppercase">giorgi kishmareia</h1>
        </div>
        <div className="flex flex-col mb-2">
          <p className="text-sm md:text-xs text-primary-4  uppercase">occupation</p>
          <h2 className="text-lg md:text-md  text-primary-7 uppercase">Software Engineer</h2>
        </div>
        <div className="flex flex-col mb-2">
          <p className="text-sm md:text-xs text-primary-4  uppercase">corporation</p>
          <p className="text-lg md:text-md  text-primary-7 uppercase">atomX</p>
        </div>
        <div className="flex flex-col mb-2">
          <p className="text-sm md:text-xs text-primary-4  uppercase">location</p>
          <p className="text-lg md:text-md  text-primary-7 uppercase">New-York, NYC</p>
        </div>
      </div>

    </>
  );
}
export default UserInfo;
