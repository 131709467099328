import HoverSound from "./HoverSound";
import React, { useRef, useState } from "react";

const BackgroundSound = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const initializeAudio = () => {
    if (!audioRef.current) {
      audioRef.current = new Audio("/sounds/backgroundsound.mp3");
      audioRef.current.loop = true;
      audioRef.current.volume = 0.1;
    }
  };
  
  const toggleAudio = () => {
    initializeAudio();
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      audioRef.current
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error("Audio playback failed:", error);
        });
    }
  };

  return (
    <div>
      <button
        onClick={() => {
          toggleAudio();
        }}
        aria-label={isPlaying ? "Mute background sound" : "Play background sound"} 
      >
        <HoverSound
          hoverSoundSrc="/sounds/process.mp3"
          className={`w-6 h-6 text-lg text-primary-7 ${isPlaying ? "icon-sound-on" : "icon-sound-off"
            }`}
        />
      </button>
    </div>
  );
};

export default BackgroundSound;
