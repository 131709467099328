import UserInfo from './UserInfo';
import Scanner from './Scanner';
import Line from './Line';
import ThemeSwitcher from './ThemeSwitcher';
import BackgroundSound from './BackgroundSound';
import ClickSound from "./ClickSound";
import HoverSound from "./HoverSound";
import { useCurrentInfo } from "../hooks/useCurrentInfo";
import { useFetchIP } from "../hooks/useFetchIP";
import { formatUptime } from "../utils/utils";
// import securityAvatar from '../images/security.png';
const SideBar = () => {
  const { currentDate, uptime, os } = useCurrentInfo();
  const ip = useFetchIP();

  return (
    <>
      {/* <div className="md:mr-5 group/line border lg:h-full lg:relative border-primary-2 relative  mb-6 md:mb-0 md:max-w-64 md:min-w-64 w-full flex flex-col items-center md:items-start md:justify-start"> */}
      <div className="group/line border lg:h-full md:border-r-0 lg:relative border-primary-2 relative  mb-6 md:mb-0 md:max-w-[254px] md:min-w-[254px] w-full flex flex-col items-center md:items-start md:justify-start">
        <div className="flex md:hidden">
          <Line />
        </div>
        <div className="text-primary-7 flex flex-col w-full">
          <div className="w-full px-2 md:px-3  justify-between md:justify-start border-b border-b-primary-2 min-h-10 h-10 flex items-center">
            <p className="text-xl">{currentDate.time}</p>
            <div className="flex items-center md:ml-auto">
              <span className="w-4 h-4 md:hidden flex items-center justify-center icon-illuminati-two text-primary-6  mx-2 text-lg"></span>
              <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <BackgroundSound />
              </ClickSound>
              <span className="w-4 h-4 md:hidden flex items-center justify-center icon-illuminati-one text-primary-6  mx-2 text-lg"></span>
            </div>

            <div className="flex md:hidden items-center md:ml-auto relative">
              <span className="relative mr-2 h-2 w-2 flex items-center justify-center">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-7 opacity-90"></span>
                <span className="relative inline-flex rounded-full h-1 w-1 bg-primary-7 opacity-75"></span>
              </span>
              <div className="flex items-center">
                <span className="text-primary-7 md:text-primary-7 flex mr-1 text-min whitespace-nowrap">Mode-></span>
                <div className="flex  gap-2 md:gap-0 not-hover-opacity">
                  <ThemeSwitcher />
                </div>
              </div>
            </div>

          </div>
          <div className="flex uppercase leading-[.93rem] justify-between px-2 md:px-3 text-xs w-full border-b border-b-primary-2 min-h-10 h-10 items-center">
            <div className="flex">
              <div className="">
                <p className="opacity-75">{currentDate.year}</p>
                <div className="flex">
                  <p className="mr-1">{currentDate.month}</p>
                  <p>{currentDate.date || "Loading date..."}</p>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="">
                <p className="opacity-75">Browser</p>
                <div className="flex">
                  <p>{currentDate.browser || "Detecting browser..."}</p>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="">
                <p className="opacity-75">Uptime</p>
                <div className="flex">
                  <p>{formatUptime(uptime)}</p>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="">
                <p className="opacity-75">Device</p>
                <div className="flex">
                  <p>{os || "Detecting OS..."}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full border-b border-b-primary-2">
            <HoverSound hoverSoundSrc="/sounds/process.mp3">
              <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <Scanner />
              </ClickSound>
            </HoverSound>
          </div>
          <div className="flex text-min w-full px-3 md:justify-start border-b border-b-primary-2 min-h-10 h-10 items-center">
            <p className="text-sm text-primary-7 ">YOUR IP - {ip}</p>
          </div>
        </div>
        <div className="flex flex-col w-full h-full">
          <div className="px-3 py-3 flex flex-col  h-full tracking-wider">
            <UserInfo />
          </div>
          <div className="flex items-end">
            <div className="group/me  flex bg-bottom duration-150 transition opacity-[.69] group-hover/line:opacity-[.93] justify-center max-w-[96px]">
             {/* <img src={securityAvatar} className="w-full group-hover/me:opacity-0 transition duration-300" alt="security avatar" /> */}
            </div>
            <div className="w-full flex justify-end duration-150 pl-16 transition opacity-[.36] group-hover/line:opacity-[.63]">
              <svg className="w-full h-full max-w-[16rem]" viewBox="0 0 433 129" fill="none">
                <g clipPath="url(#clip0_324_2)">
                  <path d="M26 65.72H225.828L241.317 81.209H309.787L363.04 136.999" className='stroke-primary-7' strokeWidth="4" />
                  <path d="M14 78C20.6274 78 26 72.6274 26 66C26 59.3726 20.6274 54 14 54C7.37258 54 2 59.3726 2 66C2 72.6274 7.37258 78 14 78Z" className='stroke-primary-7' strokeWidth="4" />
                  <path d="M150.369 78.0491H221.59L236.541 93.0001" className='stroke-primary-7' strokeWidth="2" />
                  <path d="M149.5 83C151.985 83 154 80.9853 154 78.5C154 76.0147 151.985 74 149.5 74C147.015 74 145 76.0147 145 78.5C145 80.9853 147.015 83 149.5 83Z" className='fill-primary-7' />
                  <path d="M193.5 58C195.985 58 198 55.9853 198 53.5C198 51.0147 195.985 49 193.5 49C191.015 49 189 51.0147 189 53.5C189 55.9853 191.015 58 193.5 58Z" className='fill-primary-7' />
                  <path d="M239.5 101C241.985 101 244 98.9853 244 96.5C244 94.0147 241.985 92 239.5 92C237.015 92 235 94.0147 235 96.5C235 98.9853 237.015 101 239.5 101Z" className='stroke-primary-7' strokeWidth="3" />
                  <path d="M426.5 21C428.985 21 431 18.9853 431 16.5C431 14.0147 428.985 12 426.5 12C424.015 12 422 14.0147 422 16.5C422 18.9853 424.015 21 426.5 21Z" className='stroke-primary-7' strokeWidth="3" />
                  <path d="M194.52 54.0001H282.24L297.772 38.4681H405.929L425.139 19.2581" className='stroke-primary-7' strokeWidth="2" />
                </g>
                <defs>
                  <clipPath id="clip0_324_2">
                    <rect className='w-full h-full' fill="white" transform="matrix(1 0 0 -1 0 129)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SideBar;
