import { useEffect, useState } from "react";
import Line from "./Line";
import HoverSound from "./HoverSound";

function Works() {
  const [workData, setWorkData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/data.json");
      const data = await response.json();
      setWorkData(data);
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-col p-2 md:p-5 pt-0 md:pt-0">
      {workData.map((work) => (
        <HoverSound
          key={work.id}
          hoverSoundSrc="/sounds/process.mp3"
          className={`px-2 py-2 md:px-5 md:pt-4 md:pb-5 border border-primary-2 relative transition duration-150 group/works md:hover:shadow-[0px_6px_9px_var(--primary-1)] ${work.id === workData[workData.length - 1].id ? "" : "mb-2 md:mb-5"
            }`}
        >
          <div className="hidden group-hover/works:flex">
            <Line />
          </div>
          <div className="flex justify-between items-center mb-1">
            <p className="text-md text-primary-4 group-hover/works:text-primary-7 uppercase">{work.role}</p>
            <p className="text-md text-primary-3 group-hover/works:text-primary-7">
              {work.period}
            </p>
          </div>
          <p className="text-sm text-primary-4 mb-1">{work.company}</p>
          <p className="text-xs text-primary-3 mb-1">{work.location}</p>
          <p className="text-xs text-primary-3 mb-1 md:mb-3">{work.description}</p>
          <div className="flex">
            {work.links.map((link, index) => (
              <span key={link.url} className="flex items-center">
                <a
                  className=" 
 py-1 px-2 text-xs flex items-center
 border border-primary-2
 hover:border-primary-6 hover:text-primary-7 hover:bg-primary-2
 text-primary-3
  text-ellipsis overflow-hidden"
                  target="_blank"
                  rel="noreferrer"
                  href={link.url}
                >
                  <span className="w-4 h-5 flex icon-external-link text-sm"></span>
                  {link.text}
                </a>
                {index < work.links.length - 1 && <span className="mx-1 text-xs text-primary-3">&</span>}
              </span>
            ))}
          </div>
        </HoverSound>
      ))}
    </div>
  );
}

export default Works;

