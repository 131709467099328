import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useTheme } from './ThemeContext';
import ClickSound from "./ClickSound";

const GameStatus = {
  INITIAL: 'initial',
  PLAYING: 'playing',
  PAUSED: 'paused',
  GAME_OVER: 'gameOver',
  WON: 'won',
};



const foodIcons = [
      'icon-agile', 'icon-figma', 'icon-html5', 'icon-css3', 'icon-scss', 'icon-tailwindcss',
      'icon-javascript','icon-reactjs', 'icon-typescript', 'icon-node', 'icon-ethereum', 
      'icon-blockchain', 'icon-bash-shell',  'icon-vim', 'icon-git', 'icon-code',
];

class CanvasManager {
  constructor(canvas, ctx, updateUI, config, foodIcons, mainColor, gridColor) {
    this.canvas = canvas;
    this.ctx = ctx;
    this.updateUI = updateUI;
    this.config = config;
    this.foodIcons = foodIcons;
    this.mainColor = mainColor;
    this.gridColor = gridColor;
    this.gridSize = config.gridSize;
    this.snakeSize = config.snakeSize;
    this.foodSize = config.foodSize;
    this.canvasWidth = canvas.width / config.pixelRatio;
    this.canvasHeight = canvas.height / config.pixelRatio;
    this.directionCooldown = 0;
    this.animationFrameId = null;
    this.pendingDirection = null;
    this.lastUpdate = 0;
    this.gameActive = false;
    this.isPaused = false;

    this.iconMap = {
      'icon-agile': '\ue908', 'icon-figma': '\ue902', 'icon-html5': '\ue907',
      'icon-css3': '\ue906', 'icon-scss': '\ue90d', 'icon-tailwindcss': '\ue90e',
      'icon-javascript': '\ue90a','icon-reactjs': '\ue90b', 'icon-typescript': '\ue91a', 
      'icon-node': '\ue918', 'icon-ethereum': '\ue900', 
      'icon-blockchain': '\ue901', 'icon-bash-shell': '\ue913',
      'icon-vim': '\ue919', 'icon-git': '\ue905', 'icon-code': '\ue909',
    };
    
  }

  updateDimensions(gridSize, canvasWidth, canvasHeight) {
    this.gridSize = gridSize;
    this.snakeSize = this.config.snakeSize;
    this.foodSize = this.config.foodSize;
    this.canvasWidth = canvasWidth;
    this.canvasHeight = canvasHeight;
    this.config.gridCountX = Math.floor(canvasWidth / gridSize);
    this.config.gridCountY = Math.floor(canvasHeight / gridSize);

    if (!this.gameActive) {
      this.renderInitialState();
    } else {
      this.renderCanvas();
    }
  }

  updateColors(newMainColor, newGridColor) {
    this.mainColor = newMainColor;
    this.gridColor = newGridColor;
    this.renderCanvas();
  }

  renderInitialState() {
    const startX = Math.floor(this.config.gridCountX / 2);
    const startY = Math.floor(this.config.gridCountY / 2);
    this.snake = this.createInitialSnake(startX, startY);
    this.food = { x: startX, y: startY - 5, class: this.foodIcons[0] };
    this.gameActive = false;
    this.isPaused = false;
    this.renderCanvas();
    this.updateUI({
      score: 0,
      status: GameStatus.INITIAL,
      dots: Array(this.foodIcons.length).fill(false),
      gameActive: false,
      isPaused: false,
    });
  }

  createInitialSnake(startX, startY) {
    return [
      { x: startX, y: startY - 1 },
      { x: startX, y: startY },
      { x: startX, y: startY + 1 },
      { x: startX, y: startY + 2 },
      { x: startX, y: startY + 3 },
      { x: startX - 1, y: startY + 3 },
      { x: startX - 2, y: startY + 3 },
      { x: startX - 2, y: startY + 4 },
      { x: startX - 2, y: startY + 5 },
      { x: startX - 2, y: startY + 6 },
    ];
  }

  renderGrid() {
    this.ctx.beginPath();
    this.ctx.strokeStyle = this.gridColor;
    this.ctx.lineWidth = 1;
    for (let x = 0; x <= this.config.gridCountX; x++) {
      this.ctx.moveTo(x * this.gridSize, 0);
      this.ctx.lineTo(x * this.gridSize, this.canvasHeight);
    }
    for (let y = 0; y <= this.config.gridCountY; y++) {
      this.ctx.moveTo(0, y * this.gridSize);
      this.ctx.lineTo(this.canvasWidth, y * this.gridSize);
    }
    this.ctx.stroke();
  }

  renderSnake() {
    const totalSegments = this.snake.length;
    const fadeSegments = Math.min(this.config.snakeFadeSegments, totalSegments);

    this.ctx.fillStyle = this.mainColor;
    this.snake.forEach((segment, index) => {
      let opacity;
      if (index >= totalSegments - fadeSegments) {
        const opacityStep = (totalSegments - 1 - index) / (fadeSegments - 1);
        opacity = this.config.snakeOpacityMin + opacityStep * (this.config.snakeOpacityMax - this.config.snakeOpacityMin);
      } else {
        opacity = this.config.snakeOpacityMax;
      }
      this.ctx.globalAlpha = opacity;
      this.ctx.fillRect(segment.x * this.gridSize, segment.y * this.gridSize, this.snakeSize, this.snakeSize);
    });
    this.ctx.globalAlpha = 1;
  }

  renderFood() {
    if (!this.food) return;
    const iconCode = this.iconMap[this.food.class] || '\ue913';
    this.ctx.fillStyle = this.mainColor;
    this.ctx.font = `${this.foodSize}px "icomoon"`;
    this.ctx.textAlign = 'center';
    this.ctx.textBaseline = 'middle';
    this.ctx.fillText(iconCode, this.food.x * this.gridSize + this.gridSize / 2, this.food.y * this.gridSize + this.gridSize / 2);
  }

  renderCanvas() {
    this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    this.renderGrid();
    this.renderSnake();
    this.renderFood();
  }

  initGame() {
    const startX = Math.floor(this.config.gridCountX / 2);
    const startY = Math.floor(this.config.gridCountY / 2);
    this.snake = this.createInitialSnake(startX, startY);
    this.direction = { x: 0, y: -1 };
    this.foodSequence = 0;
    this.food = { x: startX, y: startY - 5, class: this.foodIcons[0] };
    this.score = 0;
    this.gameActive = true;
    this.isPaused = false;
    this.speed = this.config.baseSpeed;
    this.lastUpdate = performance.now();
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
    this.renderCanvas();
    this.updateUI({
      score: 0,
      status: GameStatus.PLAYING,
      dots: Array(this.foodIcons.length).fill(false),
      gameActive: true,
      isPaused: false,
    });
    this.manageAnimation();
  }

  gameLoop(timestamp) {
    if (!this.gameActive || this.isPaused) return;
    if (timestamp - this.lastUpdate >= this.speed) {
      this.update();
      this.lastUpdate = timestamp;
    }
    this.animationFrameId = requestAnimationFrame(this.gameLoop.bind(this));
  }

  update() {
    if (this.pendingDirection && !this.isOppositeDirection(this.pendingDirection)) {
      this.direction = this.pendingDirection;
      this.pendingDirection = null;
    }

    const head = { x: this.snake[0].x + this.direction.x, y: this.snake[0].y + this.direction.y };
    if (this.isCollision(head)) {
      this.gameOver();
      return;
    }

    this.snake.unshift(head);
    if (head.x === this.food.x && head.y === this.food.y) {
      this.score++;
      this.foodSequence = this.score % this.foodIcons.length;
      this.speed = Math.max(this.config.maxSpeed, this.speed * this.config.speedIncrease);
      if (this.score === this.foodIcons.length) {
        this.win();
      } else {
        this.food = this.generateFood();
      }
      this.updateUI({
        score: this.score,
        dots: Array(this.foodIcons.length).fill(false).map((_, i) => i < this.score),
      });
    } else {
      this.snake.pop();
    }
    this.renderCanvas();
  }

  isCollision(pos) {
    return (
      pos.x < 0 || pos.x >= this.config.gridCountX ||
      pos.y < 0 || pos.y >= this.config.gridCountY ||
      this.snake.some((seg) => seg.x === pos.x && seg.y === pos.y)
    );
  }

  generateFood() {
    const freeCells = [];
    const occupied = new Set(this.snake.map((seg) => `${seg.x},${seg.y}`));
    for (let x = 0; x < this.config.gridCountX; x++) {
      for (let y = 0; y < this.config.gridCountY; y++) {
        if (!occupied.has(`${x},${y}`)) freeCells.push({ x, y });
      }
    }
    if (freeCells.length === 0) return null;
    const idx = Math.floor(Math.random() * freeCells.length);
    return { x: freeCells[idx].x, y: freeCells[idx].y, class: this.foodIcons[this.foodSequence] };
  }

  gameOver() {
    this.gameActive = false;
    this.isPaused = false;
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
    this.updateUI({
      status: GameStatus.GAME_OVER,
      gameActive: false,
      isPaused: false,
      activeButton: null
    });
    this.renderCanvas();
  }

  win() {
    this.gameActive = false;
    this.isPaused = false;
    this.food = null;
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
    this.updateUI({
      status: GameStatus.WON,
      gameActive: false,
      isPaused: false,
      activeButton: null
    });
    this.renderCanvas();
  }

  manageAnimation() {
    if (this.gameActive && !this.isPaused) {
      if (!this.animationFrameId) {
        this.animationFrameId = requestAnimationFrame(this.gameLoop.bind(this));
      }
    } else if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }

  handleDirection(dir) {
    const now = performance.now();
    if (now - this.directionCooldown < 20) return false;

    const newDir = { UP: { x: 0, y: -1 }, DOWN: { x: 0, y: 1 }, LEFT: { x: -1, y: 0 }, RIGHT: { x: 1, y: 0 } }[dir];
    if (newDir && !this.isOppositeDirection(newDir)) {
      this.pendingDirection = newDir;
      this.directionCooldown = now;
      return true;
    }
    return false;
  }

  isOppositeDirection(newDir) {
    return this.direction.x === -newDir.x && this.direction.y === -newDir.y;
  }

  togglePause() {
    if (!this.gameActive) return false;
    this.isPaused = !this.isPaused;
    this.updateUI({ status: this.isPaused ? GameStatus.PAUSED : GameStatus.PLAYING, isPaused: this.isPaused });
    this.manageAnimation();
    return true;
  }

  handleTapDirection(tapX, tapY) {
    if (!this.gameActive || this.isPaused) return;
    const head = this.snake[0];
    const headX = head.x * this.gridSize + this.gridSize / 2;
    const headY = head.y * this.gridSize + this.gridSize / 2;
    const deltaX = tapX - headX;
    const deltaY = tapY - headY;

    let dir;
    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      dir = deltaX > 0 ? 'RIGHT' : 'LEFT';
    } else {
      dir = deltaY > 0 ? 'DOWN' : 'UP';
    }
    this.handleDirection(dir);
  }
}

// მთავარი Snake კომპონენტი
const Snake = () => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const canvasManagerRef = useRef(null);
  const { theme } = useTheme();

  const [mainColor, setMainColor] = useState(() =>
    getComputedStyle(document.body).getPropertyValue('--primary-7').trim() || 'rgb(34 197 94)'
  );
  const [gridColor, setGridColor] = useState(() =>
    getComputedStyle(document.body).getPropertyValue('--primary-2').trim() || 'rgb(34 197 94 / 13%)'
  );

  const [gameState, setGameState] = useState({
    score: 0,
    status: GameStatus.INITIAL,
    dots: Array(foodIcons.length).fill(false),
    isPaused: false,
    gameActive: false,
    activeButton: null,
    activeDirection: null,
  });

  const getGridSize = () => parseInt(getComputedStyle(document.documentElement).getPropertyValue('--grid-size')) || 10;
  const getSnakeSize = () => parseInt(getComputedStyle(document.documentElement).getPropertyValue('--snake-size')) || 10;
  const getFoodSize = () => parseInt(getComputedStyle(document.documentElement).getPropertyValue('--food-size')) || 17;

  const getInitialGameConfig = useCallback(() => {
    const gridSize = getGridSize();
    const container = containerRef.current || { getBoundingClientRect: () => ({ width: 250, height: 350 }) };
    const { width, height } = container.getBoundingClientRect();
    const gridCountX = Math.floor(width / gridSize);
    const gridCountY = Math.floor(height / gridSize);

    return {
      baseSpeed: 95,
      speedIncrease: 0.95,
      maxSpeed: 50,
      snakeFadeSegments: 6,
      snakeOpacityMin: 0.4,
      snakeOpacityMax: 1.0,
      gridCountX,
      gridCountY,
      gridSize,
      snakeSize: getSnakeSize(),
      foodSize: getFoodSize(),
      pixelRatio: window.devicePixelRatio || 1,
    };
  }, []);

  const [gameConfig, setGameConfig] = useState(getInitialGameConfig());

  const updateCanvasSize = useCallback(() => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    if (!canvas || !container) return;

    const gridSize = getGridSize();
    const { width, height } = container.getBoundingClientRect();
    const gridCountX = Math.floor(width / gridSize);
    const gridCountY = Math.floor(height / gridSize);
    const canvasWidth = gridCountX * gridSize;
    const canvasHeight = gridCountY * gridSize;
    const pixelRatio = window.devicePixelRatio || 1;

    canvas.width = canvasWidth * pixelRatio;
    canvas.height = canvasHeight * pixelRatio;
    canvas.style.width = `${canvasWidth}px`;
    canvas.style.height = `${canvasHeight}px`;
    const ctx = canvas.getContext('2d');
    if (ctx) ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

    setGameConfig((prev) => ({
      ...prev,
      gridCountX,
      gridCountY,
      gridSize,
      snakeSize: getSnakeSize(),
      foodSize: getFoodSize(),
      pixelRatio,
    }));

    if (canvasManagerRef.current) {
      canvasManagerRef.current.updateDimensions(gridSize, canvasWidth, canvasHeight);
    }
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const resizeObserver = new ResizeObserver(() => requestAnimationFrame(updateCanvasSize));
    resizeObserver.observe(container);
    updateCanvasSize();

    return () => resizeObserver.unobserve(container);
  }, [updateCanvasSize]);

  const handleStart = useCallback(() => {
    if (!canvasManagerRef.current) return;
    canvasManagerRef.current.initGame();
    setGameState((prev) => ({
      ...prev,
      gameActive: true,
      status: GameStatus.PLAYING,
      isPaused: false,
      activeButton: 'start-control-btn',
    }));
  }, []);

  const handleDirection = useCallback(
    (dir) => {
      if (!canvasManagerRef.current || !gameState.gameActive || gameState.isPaused) return;
      if (canvasManagerRef.current.handleDirection(dir)) {
        setGameState((prev) => ({ ...prev, activeDirection: dir }));
        setTimeout(() => setGameState((prev) => ({ ...prev, activeDirection: null })), 200);
      }
    },
    [gameState.gameActive, gameState.isPaused]
  );

  const handlePause = useCallback(() => {
    if (!canvasManagerRef.current || !gameState.gameActive) return;
    if (canvasManagerRef.current.togglePause()) {
      setGameState((prev) => ({
        ...prev,
        activeButton: gameState.isPaused ? 'start-control-btn' : 'pause-btn',
      }));
    }
  }, [gameState.gameActive, gameState.isPaused]);

  const handleStartPause = useCallback(() => {
    if (!canvasManagerRef.current) return;
    if (!gameState.gameActive) {
      handleStart();
    } else if (canvasManagerRef.current.togglePause()) {
      setGameState((prev) => ({
        ...prev,
        activeButton: gameState.isPaused ? 'start-control-btn' : 'pause-btn',
      }));
    }
  }, [gameState.gameActive, gameState.isPaused, handleStart]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const updateUI = (newState) => setGameState((prev) => ({ ...prev, ...newState }));
    if (!canvasManagerRef.current) {
      canvasManagerRef.current = new CanvasManager(canvas, ctx, updateUI, gameConfig, foodIcons, mainColor, gridColor);
      canvasManagerRef.current.renderInitialState();
    }

    return () => {
      if (canvasManagerRef.current?.animationFrameId) {
        cancelAnimationFrame(canvasManagerRef.current.animationFrameId);
      }
    };
  }, [gameConfig, mainColor, gridColor]);

  useEffect(() => {
    const updateColors = () => {
      const newMainColor = getComputedStyle(document.body).getPropertyValue('--primary-7').trim() || 'rgb(34 197 94)';
      const newGridColor = getComputedStyle(document.body).getPropertyValue('--primary-2').trim() || 'rgb(34 197 94 / 12%)';
      setMainColor(newMainColor);
      setGridColor(newGridColor);
      if (canvasManagerRef.current) {
        canvasManagerRef.current.updateColors(newMainColor, newGridColor);
      }
    };
    requestAnimationFrame(updateColors);
  }, [theme]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    let touchStartX = 0;
    let touchStartY = 0;
    let touchMoved = false;

    const handleTouchStart = (e) => {
      e.preventDefault();
      const touch = e.touches[0];
      touchStartX = touch.clientX;
      touchStartY = touch.clientY;
      touchMoved = false;
    };

    const handleTouchMove = (e) => {
      e.preventDefault();
      if (!gameState.gameActive || gameState.isPaused) return;

      touchMoved = true;
      const touch = e.touches[0];
      const deltaX = touch.clientX - touchStartX;
      const deltaY = touch.clientY - touchStartY;
      const threshold = 20;

      let dir;
      if (Math.abs(deltaX) > Math.abs(deltaY) && Math.abs(deltaX) > threshold) {
        dir = deltaX > 0 ? 'RIGHT' : 'LEFT';
      } else if (Math.abs(deltaY) > threshold) {
        dir = deltaY > 0 ? 'DOWN' : 'UP';
      }

      if (dir) {
        handleDirection(dir);
        touchStartX = touch.clientX;
        touchStartY = touch.clientY;
      }
    };

    const handleTouchEnd = (e) => {
      e.preventDefault();
      if (!touchMoved && !gameState.gameActive) {
        handleStart();
      } else if (gameState.gameActive && !gameState.isPaused && !touchMoved) {
        const rect = canvas.getBoundingClientRect();
        const tapX = touchStartX - rect.left;
        const tapY = touchStartY - rect.top;
        canvasManagerRef.current?.handleTapDirection(tapX, tapY);
      }
    };

    canvas.addEventListener('touchstart', handleTouchStart, { passive: false });
    canvas.addEventListener('touchmove', handleTouchMove, { passive: false });
    canvas.addEventListener('touchend', handleTouchEnd, { passive: false });

    return () => {
      canvas.removeEventListener('touchstart', handleTouchStart);
      canvas.removeEventListener('touchmove', handleTouchMove);
      canvas.removeEventListener('touchend', handleTouchEnd);
    };
  }, [gameState.gameActive, gameState.isPaused, handleDirection, handleStart]);

  useEffect(() => {
    const handleKeydown = (e) => {
      e.preventDefault();
      const dir = {
        ArrowLeft: 'LEFT', KeyA: 'LEFT',
        ArrowUp: 'UP', KeyW: 'UP',
        ArrowRight: 'RIGHT', KeyD: 'RIGHT',
        ArrowDown: 'DOWN', KeyS: 'DOWN',
      }[e.code];
      if (dir) handleDirection(dir);
      else if (e.key === ' ' || e.key === 'Enter') handleStartPause();
    };
    document.addEventListener('keydown', handleKeydown);
    return () => document.removeEventListener('keydown', handleKeydown);
  }, [gameState.gameActive, handleStartPause, handleDirection]);

  const buttonText = useMemo(
    () => ({
      [GameStatus.INITIAL]: 'Start Game',
      [GameStatus.GAME_OVER]: 'Try Again',
      [GameStatus.WON]: 'Play Again',
    })[gameState.status] || 'Start Game',
    [gameState.status]
  );

  return (
    <div className="m-auto max-w-full max-h-full flex flex-wrap lg:flex-nowrap justify-center items-center border border-primary-2 p-2 pt-5 lg:p-5 relative">
      <div
        ref={containerRef}
        className="lg:w-[var(--game-width)] max-h-[var(--game-height)] lg:border lg:border-1 lg:border-primary-2 w-full h-full flex justify-center items-center relative transition-all duration-300 ease-in-out"
      >
        <canvas ref={canvasRef} className="w-full h-full" />
        {!gameState.gameActive && (
          <button
            id="start-btn"
            className="absolute bottom-9 z-9 py-2 px-6 text-sm border rounded border-primary-6 hover:border-primary-7 text-primary-7 hover:text-primary-7 bg-primary-2"
            onClick={handleStart}
          >
            {buttonText}
          </button>
        )}

        <div
          className={`absolute z-3 py-2 px-3 justify-center items-center text-md font-bold w-full text-center border-b border-t border-primary-6 text-primary-7 bg-primary-2 ${gameState.status === GameStatus.GAME_OVER ? 'flex' : 'hidden'
            }`}
        >
          GAME OVER
        </div>
        <div
          className={`absolute z-3 py-2 px-3 justify-center items-center text-md font-bold w-full text-center border-b border-t border-primary-6 text-primary-7 bg-primary-2 ${gameState.status === GameStatus.WON ? 'flex' : 'hidden'
            }`}
        >
          YOU GOT ME✌️
        </div>
        <div
          className={`absolute z-3 py-2 px-3 justify-center items-center text-md font-bold w-full text-center border-b border-t border-primary-6 text-primary-7 bg-primary-2 ${gameState.status === GameStatus.PAUSED ? 'flex' : 'hidden'
            }`}
        >
          Paused
        </div>
        <div className="flex w-full items-center justify-between absolute left-1 -top-5 text-xs text-primary-6">
          <div className="flex items-center">
            <span className="mr-1">Score:</span>
            <span>{gameState.score}</span>
          </div>
          <span className="flex lg:hidden">Swipe / Click</span>
          <div className="flex items-center">
            <span>{Math.round((gameState.score / foodIcons.length) * 100)}%</span>
            <span className="ml-1">:Progress</span>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center flex-col items-center w-full mt-2 lg:mt-0 lg:max-w-[250px] lg:w-[250px] lg:min-w-[250px] lg:h-[350px]  border border-primary-2 lg:border-l-0">

        <div className="w-full lg:w-auto inline-flex flex-col p-2 lg:p-3 items-center justify-center m-auto lg:border lg:border-primary-2 lg:bg-primary-2 rounded">
          <div className="lg:flex hidden flex-col mb-3 text-center">
            <p className="text-sm text-primary-7">Enter - Space</p>
            <p className="text-sm text-primary-7">W S A D -- ↑ ↓ ← →</p>
          </div>
          <div className="flex flex-col items-center gap-x-2 lg:gap-3 w-full">
            <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
              <button
                className={`hidden lg:block w-[50px] h-[36px] rounded text-sm transition border border-1 hover:bg-primary-6 hover:border-primary-7 ${gameState.activeDirection === 'UP'
                  ? 'text-primary-7 border-primary-7 bg-primary-6'
                  : 'text-primary-7 border-primary-6'
                  }`}
                onMouseDown={() => handleDirection('UP')}
              >
                ↑
              </button>
            </ClickSound>
            <div className="hidden lg:flex gap-3">
              <ClickSound clicsuccesskSoundSrc="/sounds/ui-click.mp3">
                <button
                  className={`w-[50px] h-[36px] rounded text-sm transition border border-1 hover:bg-primary-6 hover:border-primary-7 ${gameState.activeDirection === 'LEFT'
                    ? 'text-primary-7 border-primary-7 bg-primary-6 '
                    : 'text-primary-7 border-primary-6'
                    }`}
                  onMouseDown={() => handleDirection('LEFT')}
                >
                  ←
                </button>
              </ClickSound>
              <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <button
                  className={`w-[50px] h-[36px] rounded text-sm transition border border-1 hover:bg-primary-6 hover:border-primary-7 ${gameState.activeDirection === 'DOWN'
                    ? 'text-primary-7 border-primary-7 bg-primary-6 '
                    : 'text-primary-7 border-primary-6'
                    }`}
                  onMouseDown={() => handleDirection('DOWN')}
                >
                  ↓
                </button>
              </ClickSound>
              <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <button
                  className={`w-[50px] h-[36px] rounded text-sm transition border border-1 hover:bg-primary-6 hover:border-primary-7 ${gameState.activeDirection === 'RIGHT'
                    ? 'text-primary-7 border-primary-7 bg-primary-6 '
                    : 'text-primary-7 border-primary-6'
                    }`}
                  onMouseDown={() => handleDirection('RIGHT')}
                >
                  →
                </button>
              </ClickSound>
            </div>
            <div className="flex w-full gap-2 lg:gap-3">
              <ClickSound className="w-full" clickSoundSrc="/sounds/ui-click.mp3">
                <button
                  className={`w-full h-[36px] lg:rounded text-sm transition border border-1 hover:bg-primary-6 hover:border-primary-7 ${gameState.activeButton === 'start-control-btn'
                    ? 'text-primary-7 border-primary-6 bg-primary-2 lg:border-primary-7 lg:bg-primary-6'
                    : 'text-primary-7 border-primary-6'
                    }`}
                  onMouseDown={handleStartPause}
                >
                  Start
                </button>
              </ClickSound>
              <ClickSound className="w-full" clickSoundSrc="/sounds/ui-click.mp3">
                <button
                  className={`w-full h-[36px] lg:rounded text-sm transition border border-1 hover:bg-primary-6 hover:border-primary-7 ${gameState.activeButton === 'pause-btn'
                    ? 'text-primary-7 border-primary-6 bg-primary-2 lg:border-primary-7 lg:bg-primary-6'
                    : 'text-primary-7 border-primary-6'
                    }`}
                  onMouseDown={handlePause}
                >
                  Pause
                </button>
              </ClickSound>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-center gap-2 w-full border-t border-t-primary-2 p-2 lg:p-4 max-w-full">
          {foodIcons.map((icon, idx) => (
            <div
              key={idx}
              className={`w-5 h-5 flex items-center justify-center rounded-full border text-sm border-primary-6 transition-all ${gameState.dots[idx] ? 'bg-primary-2 text-primary-7 scale-125 animate-pulse' : ''
                }`}
            >
              {gameState.dots[idx] && <span className={icon} />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Snake;