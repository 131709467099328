import Line from './Line';
import React, { useState, useEffect } from 'react';
function Scanner() {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const handleLoad = () => {
      setIsLoaded(true); 
    };
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <>
      <div className="bg-grid relative overflow-hidden group bg-[length:12px_12px] cursor-pointer flex items-center justify-center relative w-[100%] h-[205px] min-h-[205px]  md:w-[100%] md:h-[133px] md:min-h-[133px]">
        <div className="hidden group-hover:flex md:w-24 md:h-24 w-32 h-32 absolute">
          <Line />
        </div>
        <div className="absolute left-0 top-0 w-full h-full flex justify-center items-center z-1 overflow-hidden animate-pulse icon-finger font-thin text-[7rem] md:text-[5rem] text-primary-7">
        </div>
          <div className={`z-2 absolute top-0 top-[-10px] h-[1px] w-full bg-primary-7 animate-duration-[3.5s] animate-delay-[1.5s] shadow-[0px_0px_57px_5px_var(--primary-7)] ${isLoaded ? 'animate-[radarVertical] animate-iteration-count-infinite' : ''}`}></div>
      </div>
    </>
  );
}
export default Scanner;