import React, { useState } from 'react';
import Skills from './Skills';
import Certificates from './Certificates';
import About from './About';
import Works from './Works';
import Line from './Line';
import ThemeSwitcher from './ThemeSwitcher';
import HoverSound from "./HoverSound";
import ClickSound from "./ClickSound";
import Snake from './Snake';

function Content() {
  const [activeButton, setActiveButton] = useState('workHistory');
  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  return (
    <>
      <div className="flex flex-wrap w-full justify-center h-full relative ">
        <div className="hidden ">
          <Line />
        </div>
        <div className="flex flex-col w-full lg:w-3/6 border border-primary-2 relative lg:h-full flex  mb-6 md:mb-5 group/treangle">
          <div className="flex md:hidden lg:hidden">
            <Line />
          </div>
          <div className="border-b border-b-primary-2 min-h-10 h-10 flex items-center px-3 pr-2 md:pl-6 md:pr-4">
            <p className="xl:text-md lg:text-sm text-sm whitespace-nowrap  text-primary-4 uppercase">to collaborate on • UI/AI  </p>
            <div className="flex pl-5 lg:hidden items-center ml-auto relative">
              <span className="relative md:mr-2 h-2 w-2 flex items-center justify-center">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-7 opacity-90"></span>
                <span className="relative inline-flex rounded-full h-1 w-1 bg-primary-7 opacity-75"></span>
              </span>
              <div className="hidden md:flex items-center">
                <span className="text-primary-7 md:text-primary-7 hidden md:flex mr-1 text-min whitespace-nowrap">Mode-></span>
                <div className="flex  gap-2 md:gap-0 not-hover-opacity">
                  <ThemeSwitcher />
                </div>
              </div>
            </div>
          </div>
          <div className="p-2 md:p-5 h-full overflow-y-auto flex flex-col">
            <Skills />
            <Certificates />
            <About />
          </div>
          <div className="border-t border-t-primary-2 min-h-10 h-10 flex items-center pl-4 mt-auto justify-center lg:justify-start pr-4">
            <span className="text-primary-7 text-lg  duration-150 transition group-hover/treangle:opacity-1 transform rotate-180 opacity-[0.39] icon-theilu"></span>
          </div>
        </div>

        <div className=" w-full lg:w-3/6 border border-primary-2 lg:border-l-transparent relative lg:h-full flex flex-col group/treangle">
          <div className="flex lg:hidden">
            <Line />
          </div>
          <div className="border-b border-b-primary-2 min-h-10 h-10 flex items-center px-3 pr-2 md:pl-6 md:pr-4">
            <p className="xl:text-md lg:text-sm text-sm whitespace-nowrap  text-primary-4 uppercase">MY-WORKS • </p>
            <div className="flex pl-5 items-center ml-auto relative">
              <span className="relative mr-2 h-2 w-2 flex items-center justify-center">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-7 opacity-90"></span>
                <span className="relative inline-flex rounded-full h-1 w-1 bg-primary-7 opacity-75"></span>
              </span>
              <div className="flex items-center">
                <span className="text-primary-7 md:text-primary-7 flex mr-1 text-min whitespace-nowrap">Mode-></span>
                <div className="flex  gap-2 md:gap-0 not-hover-opacity">
                  <ThemeSwitcher />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex p-2 md:p-5 gap-2 md:gap-5">

            <ClickSound clickSoundSrc="/sounds/ui-click.mp3" className="w-full">
              <HoverSound hoverSoundSrc="/sounds/process.mp3" className="w-full">
                <button
                  className={`relative overflow-hidden p-2 w-full text-sm h-10 min-h-10 border hover:text-primary-7 hover:bg-primary-2
                  ${activeButton === 'workHistory' ? 'border-primary-6 hover:border-primary-6 bg-primary-2 text-primary-7' : 'text-primary-4 border-primary-2 hover:border-primary-2 '}`}
                  aria-label="Go to works history"
                  onClick={() => handleButtonClick('workHistory')}>   
                  WORK HISTORY
                  <div className={`z-2 absolute top-0 left-[-50px] border-x border-x-1 border-x-primary-7 w-[10px] animate-duration-[5s] animate-delay-[1s]  h-full shadow-[0px_0px_35px_5px_var(--primary-7)] animate-[radarHorizon] animate-iteration-count-infinite ${activeButton === 'workHistory' ? 'hidden' : ''}`}></div>
                </button>
              </HoverSound>
            </ClickSound>




            <ClickSound clickSoundSrc="/sounds/ui-click.mp3" className="w-full">
              <HoverSound hoverSoundSrc="/sounds/process.mp3" className="w-full">
                <button
                  className={`relative overflow-hidden p-2 w-full text-sm h-10 min-h-10 border hover:text-primary-7 hover:bg-primary-2
                  ${activeButton === 'presentationWorks' ? 'border-primary-6 hover:border-primary-6 bg-primary-2 text-primary-7' : 'text-primary-4 border-primary-2 hover:border-primary-2 '}`}
                  aria-label="Go to presentation works"
                  onClick={() => handleButtonClick('presentationWorks')}>   
                  TRY ME!
                  <div className={`z-2  absolute top-0 left-[-50px] border-x border-x-1 border-x-primary-7 w-[10px] animate-duration-[5s] animate-delay-[1s]  h-full shadow-[0px_0px_35px_5px_var(--primary-7)] animate-[radarHorizon] animate-iteration-count-infinite ${activeButton === 'presentationWorks' ? 'hidden' : ''}`}></div>
                </button>
              </HoverSound>
            </ClickSound>
          </div>





          <div className={activeButton === 'presentationWorks' ? 'flex h-full w-full flex-col' : 'hidden'}>
            <div className="flex flex-col p-2 md:p-5 pt-0 md:pt-0 h-full">
              <div className="flex flex-col items-center justify-center p-2 md:px-5 md:py-4 border w-full h-full border-primary-2 relative transition duration-150">
                <Snake />
              </div>

            </div>
          </div>

          <div className={activeButton === 'workHistory' ? 'flex flex-col overflow-y-auto' : 'hidden'}>
            <Works />
          </div>

          <div className="border-t border-t-primary-2 min-h-10 h-10 flex items-center justify-center lg:justify-end px-4 w-full mt-auto">
          <span className="text-primary-7 text-lg  duration-150 transition group-hover/treangle:opacity-1 transform rotate-180 opacity-[0.39] icon-theilu"></span>
          </div>
        </div>
      </div>

    </>
  );
}
export default Content;
