import Line from './Line';
import HoverSound from "./HoverSound";
function Certificates() {
  return (
    <>
      <div className="flex flex-col border border-primary-2 lg:border-t-0 mb-2 md:mb-5 lg:mb-0 relative">
        <div className="hidden md:flex lg:hidden">
          <Line />
        </div>
        <div className="border-b border-b-primary-2 min-h-10 h-10 flex items-center px-2 md:px-5">
          <p className="xl:text-md lg:text-sm text-sm whitespace-nowrap  text-primary-4 uppercase">Certificates:</p>
        </div>
        <ul className="p-2 md:p-5">
          <li className="flex mb-1">

            <HoverSound hoverSoundSrc="/sounds/process.mp3" className="flex">
              <a className=" flex items-center
                  py-1 px-2 text-xs md:text-sm
                  border border-primary-2 text-primary-7 hover:border-primary-6 hover:bg-primary-2
                  
                 " target="_blank" rel="noreferrer" href="https://www.coursera.org/learner/q1sh101">
                <span className="w-5 h-5 flex icon-external-link text-sm"></span>
                <span>Coursera →</span>
              </a>
            </HoverSound>
          </li>
          <li className="flex mb-1">
            <HoverSound hoverSoundSrc="/sounds/process.mp3" className="flex">
              <a className=" flex items-center
                  py-1 px-2 text-xs md:text-sm
                  border border-primary-2 text-primary-7 hover:border-primary-6 hover:bg-primary-2
                  
                 " target="_blank" rel="noreferrer" href="https://www.codecademy.com/profiles/q1sh101">
                <span className="w-5 h-5 flex icon-external-link text-sm"></span>
                <span>CodeCademy → → </span>
              </a>
            </HoverSound>
          </li>
          <li className="flex">
            <HoverSound hoverSoundSrc="/sounds/process.mp3" className="flex">
              <a className=" flex items-center
                  py-1 px-2 text-xs md:text-sm
                  border border-primary-2 text-primary-7 hover:border-primary-6 hover:bg-primary-2
                  
                 " target="_blank" rel="noreferrer" href="https://www.hackerrank.com/profile/q1sh101">
                <span className="w-5 h-5 flex icon-external-link text-sm"></span>
                <span>HackerRank → → →</span>
              </a>
            </HoverSound>
          </li>
        </ul>
      </div>
    </>
  );
}
export default Certificates;
