import React from 'react';
import Header from './components/Header';
import Wrapper from './components/Wrapper';
import Footer from './components/Footer';
import { ThemeProvider, } from './components/ThemeContext';
import CustomCursor from "./components/CustomCursor";
import SocialDock from "./components/SocialDock";

import './App.css';
function App() {
  return (
    <>
      <ThemeProvider>
        <div className="relative z-[111] hidden md:flex">
          <CustomCursor />
        </div>
        <div className="flex flex-col w-full h-full items-center justify-center relative z-2 overflow-hidden">
          <div className="hidden sm:flex w-[360px] shadow-[0px_0px_250px_var(--primary-7)] h-[360px] pointer-events-none rotate-45 absolute -right-[320px] -top-[320px] before before:absolute before:w-full before:h-full before:left-0 before:top-0"></div>
          <Header />
          <Wrapper />
          <Footer />
          <SocialDock />
        </div>

      </ThemeProvider>
    </>
  );
}

export default App;
